<template>
  <div class="table-widget border-0 m-0">
    <h5 class="widget-title">Table Widget</h5>
    <div class="table-responsive">
      <table-component class="table">
        <template v-slot:table-header>
          <th scope="col" style="cursor:pointer" v-for="element in data.activeColumns" :key="element.key">
            <span>{{ element.header }}</span>
          </th>
        </template>
        <template #table-body>
          <tr v-for="item in data.items" :key="item.id">
            <td v-for="tp in data.activeColumns" :key="tp.key">
              <slot v-if="$slots[tp.key]" :item="item">{{ $slots[tp.key]({ item }) }}</slot>
              <template v-else-if="item[tp.key] !== undefined">
                {{ item[tp.key] }}
              </template>
            </td>
          </tr>
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableWidget',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.table-widget {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  min-height: 300px;
  max-height: 100%;
  min-width: 300px;
  max-width: 100%;
}

.widget-title {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 16px;
}

.table-responsive {
  overflow-x: auto;
  max-height: 400px;
}

.table {
  min-width: 300px;
  margin-bottom: 0;
  border-collapse: collapse;
}

.table th, .table td {
  text-align: left;
  border-bottom: 1px solid #ddd;
}

</style>
