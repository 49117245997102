import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import Multiselect from '@vueform/multiselect';
import BaseSelect from "@/components/base/BaseSelect.vue";
import CardComponent from "@/components/base/Card.vue";
import SideNav from "@/components/base/SideNav.vue";
import ModalComponent from "@/components/base/Modal.vue";
import ModalButton from "@/components/base/ModalButton.vue";
import Button from "@/components/base/Button.vue";
import Table from "@/components/base/Table.vue";
import Pagination from "@/components/base/Pagination.vue";
import FailOverPage from "@/components/base/FailOverPage.vue";
import ButtonGotoUp from "@/components/base/ButtonGotoUp.vue";
import LoadingScreen from "@/components/base/LoadingScreen.vue";
import DataTable  from 'datatables.net-vue3';
import CrudButton from "@/components/base/CrudButton.vue";
import StatWidget from "@/components/base/StatWidget.vue";
import TableWidget from "@/components/base/TableWidget.vue";
import ChartWidget from "@/components/base/ChartWidget.vue";
export default (VueApp) => {
    VueApp.component('font-awesome-icon', FontAwesomeIcon);
    VueApp.component('font-awesome-layers', FontAwesomeLayers);
    VueApp.component('card-component', CardComponent);
    VueApp.component('multi-select', Multiselect);
    VueApp.component('base-select', BaseSelect);
    VueApp.component('side-nav', SideNav);
    VueApp.component('modal-component',ModalComponent);
    VueApp.component('modal-button', ModalButton);
    VueApp.component('btn-component', Button);
    VueApp.component('button-goto-up', ButtonGotoUp);
    VueApp.component('table-component', Table);
    VueApp.component('pagination-component', Pagination);
    VueApp.component('fail-over-page', FailOverPage);
    VueApp.component('loading-screen', LoadingScreen);
    VueApp.component('data-table', DataTable);
    VueApp.component('crud-button', CrudButton);
    VueApp.component('stat-widget', StatWidget);
    VueApp.component('table-widget', TableWidget);
    VueApp.component('chart-widget', ChartWidget);

    return VueApp;
};
