<template>
  <div class="stat-widget">
    <h5 class="widget-title">Stat Widget</h5>
    <p class="widget-stat">{{ data.value }}</p>
  </div>
</template>

<script>
export default {
  name: 'StatWidget',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.stat-widget {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.widget-title {
  font-size: 1.25rem;
  color: #333;
}

.widget-stat {
  font-size: 2.5rem;
  font-weight: bold;
  color: #007bff;
}
</style>
